<template>
  <Dialog :commonDialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title> Record Payment for {{ detail.barcode }} </template>
    <template v-slot:body>
      <v-container fluid>
        <v-form
          ref="paymentForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit()"
        >
          <v-row>
            <v-col md="6">
              <v-layout>
                <v-flex md4
                  ><label for="amount-received" class="required"
                    >Amount Received</label
                  ></v-flex
                >
                <v-flex md8>
                  <v-text-field
                    dense
                    id="amount-received"
                    color="cyan"
                    filled
                    type="number"
                    min="0"
                    :disabled="pageLoading"
                    v-model.trim="paymentCreate.received"
                    label="Amount Received"
                    :rules="[
                      validateRules.required(
                        paymentCreate.received,
                        'Amount Received'
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                    v-on:keypress="limitDecimal($event, paymentCreate.received)"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout>
                <v-flex md4
                  ><label for="payment-mode" class="required"
                    >Payment mode</label
                  ></v-flex
                >
                <v-flex md8>
                  <v-select
                    id="payment-mode"
                    :items="paymentModeList"
                    dense
                    filled
                    solo
                    flat
                    hide-details
                    :rules="[
                      validateRules.required(
                        paymentCreate.payment_mode,
                        'Payment mode'
                      ),
                    ]"
                    :disabled="pageLoading"
                    v-model.trim="paymentCreate.payment_mode"
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="managePaymentModeDialog = true"
                    label="Payment mode"
                    color="cyan"
                    item-text="text"
                    item-value="id"
                    item-color="cyan"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout>
                <v-flex md4
                  ><label for="payment-date">Payment Date</label></v-flex
                >
                <v-flex md8>
                  <DatePicker
                    solo
                    id="payment-date"
                    :min-date="paymentMinDate"
                    :pageLoading="pageLoading"
                    placeholder="Payment Date"
                    v-on:update:date-picker="setPaymentDate"
                  ></DatePicker>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout>
                <v-flex md4
                  ><label for="transaction-id">Transaction ID</label></v-flex
                >
                <v-flex md8>
                  <v-text-field
                    id="transaction-id"
                    dense
                    color="cyan"
                    filled
                    :disabled="pageLoading"
                    v-model.trim="paymentCreate.transaction_id"
                    label="Transaction ID"
                    :rules="[
                      validateRules.minLength(
                        paymentCreate.transaction_id,
                        'Transaction ID',
                        1
                      ),
                      validateRules.maxLength(
                        paymentCreate.transaction_id,
                        'Transaction ID',
                        100
                      ),
                    ]"
                    solo
                    flat
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="12">
              <label for="note">Leave a note</label>
              <v-textarea
                dense
                filled
                solo
                flat
                v-model="paymentCreate.remarks"
              ></v-textarea>
            </v-col>
            <v-col md="12">
              <label for="attachments">Attachments</label>
              <FileTemplate
                id="attachments"
                v-on:file:updated="updateFiles"
                allowUpload
              ></FileTemplate>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <ManagePaymentMode
        :commonDialog="managePaymentModeDialog"
        :paymentMode="paymentModeList"
        v-on:close-dialog="managePaymentModeDialog = false"
        v-on:get-payment-mode="getAttributes(['payment_modes'])"
      ></ManagePaymentMode>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="$emit('close', true)"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Payment
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import moment from "moment-timezone";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, POST, GET } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
// import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import ManagePaymentMode from "@/view/pages/partials/Manage-Payment-Mode.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [ValidationMixin],
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formLoading: false,
      pageLoading: false,
      paymentMinDate: null,
      managePaymentModeDialog: false,
      paymentModeList: [],
      paymentCreate: {
        received: null,
        payment_mode: null,
        transaction_id: null,
        admin_notes: null,
        payment_date: null,
        documents: null,
        barcode: null,
      },
    };
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.paymentCreate.received =
          this.detail.total - this.detail.paid_amount;
      },
    },
  },
  methods: {
    setPaymentDate(param) {
      this.paymentCreate.payment_date = param;
    },
    updateFiles(param) {
      this.paymentCreate.documents = param;
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.paymentForm.validate()) {
        return false;
      }

      const formData = {
        action: "mark_as_paid",
        invoice: _this.lodash.toSafeInteger(_this.detail.id) || 0,
        amount: _this.lodash.toNumber(_this.paymentCreate.received) || 0.0,
        payment_date: _this.paymentCreate.payment_date || null,
        admin_note: _this.paymentCreate.admin_notes || null,
        transaction_id: _this.paymentCreate.transaction_id || null,
        payment_mode:
          _this.lodash.toSafeInteger(_this.paymentCreate.payment_mode) || 0,
        documents: _this.paymentCreate.documents || [],
      };

      const pendingAmount = _this.lodash.toNumber(_this.detail.pending_amount);

      if (pendingAmount < formData.amount) {
        ErrorEventBus.$emit(
          "update:error",
          `Amount received must be less than or equal to ${pendingAmount}`
        );
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, { url: "payment", data: formData })
        .then(() => {
          _this.$emit("close", true);
          _this.$emit("success", true);
        })
        .catch((error) => {
          _this.logError(error);
          _this.$emit("error", true);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getAttributes(attribute) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "payment/options",
          data: { attribute },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            if (_this.lodash.isEmpty(data.payment_modes) === false) {
              _this.paymentModeList = data.payment_modes;
            }
            if (data.barcode) {
              _this.paymentCreate.barcode = data.barcode;
            }
          }
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getInvoice() {
      this.$store
        .dispatch(GET, { url: "invoice/" + this.invoiceId })
        .then(({ data }) => {
          this.detail = data;

          this.moreActions = data.more_actions;

          this.getLineItems({
            invoice: this.detail.id,
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Invoice", route: "invoice" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  mounted() {
    this.getAttributes(["payment_modes", "barcode"]);
    this.paymentMinDate = moment().format("YYYY-MM-DD");
  },
  components: {
    Dialog,
    DatePicker,
    FileTemplate,
    // editor: TinyMCE,
    ManagePaymentMode,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
